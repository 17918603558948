import ahoy from 'ahoy.js';

const analyticsObservableInit = () => {
  if (typeof window.IntersectionObserver !== 'function') {
    ahoy.track('observe:init_failure');
    return;
  }

  ahoy.track('observe:init_success');

  const IntersectionObserver = window.IntersectionObserver;
  const viewportHeight = window.innerHeight;
  const observableElements = Array.from(document.querySelectorAll('[data-observable]'));
  const observableElementsWithHeight = observableElements.map((element) => {
    return [element, element.getBoundingClientRect().height];
  });

  const callback = function(entries,) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        ahoy.track(`observe:${entry.target.getAttribute('data-observable')}`);
      }
    });
  };

  observableElementsWithHeight.forEach(([element, height]) => {
    const threshold = viewportHeight >= height ? 1.0 : Math.floor(viewportHeight * 10 / height) / 10;
    new IntersectionObserver(callback, {
      threshold: threshold
    }).observe(element);
  });
};

const analyticsTrackableInit = () => {
  const clickTrackableElements = Array.from(document.querySelectorAll('[data-click-trackable]'));

  clickTrackableElements.forEach(element => {
    element.addEventListener('click', function (_) {
      ahoy.track(`trackable:click_${element.getAttribute('data-click-trackable')}`);
    });
  });
};

export {
  analyticsObservableInit,
  analyticsTrackableInit,
};

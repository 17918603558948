import 'fslightbox';
import Swiper from 'swiper';
import { DataTable } from 'simple-datatables';
import {
  analyticsObservableInit,
  analyticsTrackableInit,
} from 'javascripts/analytics';

/**
 * Init analytics
 */
analyticsObservableInit();
analyticsTrackableInit();

const headerInit = () => {
  const headerElement = document.querySelector('.main-header');
  if (!headerElement) {
    return;
  }

  const body = document.querySelector('body');
  const headerOverlay = headerElement.querySelector('.main-header-overlay');

  // element with all navigation items - links, phone, cart, auth
  // this element shared between desktop and mobile view
  const headerNavigation = headerElement.querySelector('.main-header-right');
  // navigation items with dropdown
  const headerNavigationItemsWithDropdown = headerNavigation.querySelectorAll('.main-header-nav__item--dropdown');

  // element with mobile menu controls
  const headerMobile = headerElement.querySelector('.main-header-mobile');
  // element that toggles mobile navigation
  const headerMobileToggleElement = headerMobile.querySelector('.main-header-burger');

  // element with search controls
  const headerSearch = headerElement.querySelector('.main-header-search');
  // element that toggles header search
  const headerSearchToggleElement = headerSearch.querySelector('.main-header-search-btn');
  // search container
  const headerSearchContainer = headerSearch.querySelector('.main-header-search-container');
  // search close button
  const headerSearchCloseElement = headerSearch.querySelector('.main-header-search-actions__reset');

  // header states
  const isHeaderMobileView = () => window.getComputedStyle(headerMobile).display !== 'none';
  const isMobileNavigationActive = () => headerMobile.querySelector('.main-header-burger.active') !== null;
  const isAnyNavItemWithDropdownActive = () => headerNavigation.querySelector('.main-header-nav__item--dropdown.active') !== null;
  const isSearchActive = () => headerSearch.querySelector('.main-header-search-btn.active') !== null;

  // body must be locked if mobile menu opened in mobile view or any dropdown opened in desktop view or search opened
  const synchronizeBodyState = () => {
    let isHeaderMobileViewNow = isHeaderMobileView();
    let isScrollLockedOnDesktop = (!isHeaderMobileViewNow && isAnyNavItemWithDropdownActive()) || isSearchActive();
    let isScrollLockedOnMobile = (isHeaderMobileViewNow && isMobileNavigationActive()) || isSearchActive();

    if (isScrollLockedOnDesktop || isScrollLockedOnMobile) {
      body.classList.add('scroll-locked');
    } else {
      body.classList.remove('scroll-locked');
    }
  };

  // header overlay must be active if any dropdown opened
  const synchronizeHeaderOverlayState = () => {
    if (isAnyNavItemWithDropdownActive() || isSearchActive()) {
      headerOverlay.classList.add('main-header-overlay--visible');
    } else {
      headerOverlay.classList.remove('main-header-overlay--visible');
    }
  };

  const headerToggle = (toggleElement, containerElement, active) => {
    if (active) {
      // close if it active
      toggleElement.classList.remove('active');
      containerElement.classList.remove('show');
    } else {
      // close search menu
      // set active to true for forcing toggle off
      searchToggle(headerSearchToggleElement, headerSearchContainer, true);

      // open if it inactive
      toggleElement.classList.add('active');
      containerElement.classList.add('show');
    }

    synchronizeBodyState();
    synchronizeHeaderOverlayState();
  };

  const searchToggle = (toggleElement, containerElement, active) => {
    if (active) {
      // close if it active
      toggleElement.classList.remove('active');
      containerElement.classList.remove('show');
    } else {
      // close all before open
      closeAll();

      // open if it inactive
      toggleElement.classList.add('active');
      containerElement.classList.add('show');
    }

    synchronizeBodyState();
    synchronizeHeaderOverlayState();
  };

  const closeAll = () => {
    // close all dropdowns
    headerNavigationItemsWithDropdown.forEach((toggleElement) => {
      // set active to true for forcing toggle off
      headerToggle(toggleElement, toggleElement.querySelector('.main-header-dropdown'), true);
    });

    // close mobile menu
    // set active to true for forcing toggle off
    headerToggle(headerMobileToggleElement, headerNavigation, true);

    // close search menu
    // set active to true for forcing toggle off
    searchToggle(headerSearchToggleElement, headerSearchContainer, true);
  };

  // Bind events

  // open/close navigation item with dropdown
  headerNavigationItemsWithDropdown.forEach((navigtationItemWithDropdown) => {
    navigtationItemWithDropdown.addEventListener('click', (event) => {
      const toggleElement = event.currentTarget;
      const isActive = toggleElement.classList.contains('active');
      const containerElement = toggleElement.querySelector('.main-header-dropdown');

      headerToggle(toggleElement, containerElement, isActive);
    });
  });

  // open/close mobile navigation
  headerMobileToggleElement.addEventListener('click', (event) => {
    const toggleElement = event.currentTarget;
    const isActive = toggleElement.classList.contains('active');
    const containerElement = headerNavigation;

    headerToggle(toggleElement, containerElement, isActive);
  });

  // open/close header search
  headerSearchToggleElement.addEventListener('click', (event) => {
    const toggleElement = event.currentTarget;
    const isActive = toggleElement.classList.contains('active');
    const containerElement = headerSearchContainer;

    searchToggle(toggleElement, containerElement, isActive);
  });

  // close search when we click on close, closeAll will do the same :/
  headerSearchCloseElement.addEventListener('click', closeAll);

  // close all active navigation dropdowns and mobile navigation
  headerOverlay.addEventListener('click', closeAll);

  // synchronize states on window resize
  window.addEventListener('resize', () => {
    synchronizeBodyState();
    synchronizeHeaderOverlayState();
  });
};
headerInit();

const mainSliderInit = () => {
  new Swiper('.main-intro-slider', {
    pagination: {
      el: '.main-intro-slider__nav',
      clickable: true,
      renderBullet: function (index, className) {
        return `
                  <button class="main-top__button ${className}" type="button" aria-label="Кнопка слайдера">
                    <div class="main-top__line"></div>
                  </button>
                `;
      },
    },
    paginationClickable: true,
    speed: 1000,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: 2500,
    },
  });
};
mainSliderInit();

const cartInit = () => {
  const cartElement = document.querySelector('.cart');
  if (!cartElement) {
    return;
  }

  const cartPromoElement = cartElement.querySelector('.cart__promo');
  if (!cartPromoElement) {
    return;
  }

  const cartPromoOpenFormElement = cartPromoElement.querySelector('.cart__button-text');
  const cartPromoFormLabelElement = cartPromoElement.querySelector('.cart__label');
  const cartPromoFormSubmitEnterElement = cartPromoElement.querySelector('.cart__button-enter');

  cartPromoOpenFormElement.addEventListener('click', () => {
    cartPromoOpenFormElement.classList.add('cart__button-text--hidden');
    cartPromoFormLabelElement.classList.add('cart__label--show');
    cartPromoFormSubmitEnterElement.classList.add('cart__button-enter--show');
  });
};
cartInit();

const flashInit = () => {
  const flashMessageCloseElements = document.querySelectorAll('.flash-message-close');

  flashMessageCloseElements.forEach((flashMessageCloseElement) => {
    flashMessageCloseElement.addEventListener('click', (event) => {
      const closeElement = event.currentTarget;
      closeElement.closest('.flash-message')
        .remove();
    });
  });
};
flashInit();

const catalogSearchFormInit = () => {
  const catalogSearchFormElement = document.querySelector('form.js-catalog-search-form');
  if (!catalogSearchFormElement) {
    return;
  }

  const catalogSearchFormSelectOrderElement = catalogSearchFormElement.querySelector('.js-order-selection');
  catalogSearchFormSelectOrderElement.addEventListener('change', () => catalogSearchFormElement.submit());

  catalogSearchFormElement.addEventListener('reset', (event) => {
    event.preventDefault();

    const inputs = catalogSearchFormElement.getElementsByTagName('input');
    const selects = catalogSearchFormElement.getElementsByTagName('select');
    const textareas = catalogSearchFormElement.getElementsByTagName('textarea');

    // clearing inputs
    for (let input of inputs) {
      switch (input.type) {
        case 'text':
          input.value = '';
          break;
        case 'radio':
        case 'checkbox':
          input.checked = false;
      }
    }

    // clearing selects
    for (let select of selects) {
      select.selectedIndex = 0;
    }

    // clearing textareas
    for (let textarea of textareas) {
      textarea.innerHTML = '';
    }

    return false;
  });
};
catalogSearchFormInit();

const catalogFilterModalInit = () => {
  const catalogFilterModal = document.querySelector('.catalog-filter-modal');
  if (!catalogFilterModal) {
    return;
  }

  const body = document.querySelector('body');
  const catalogFilterShowButton = catalogFilterModal.querySelector('.catalog-filter-modal__btn');
  const catalogFilterContainer = document.querySelector('.catalog-filter');
  const catalogFilterCloseButton = catalogFilterContainer.querySelector('.catalog-filter-close');

  function toggle() {
    catalogFilterShowButton.classList.toggle('active');
    catalogFilterContainer.classList.toggle('catalog-filter--open');
    body.classList.toggle('scroll-locked');
  }

  catalogFilterShowButton.addEventListener('click', toggle);
  catalogFilterCloseButton.addEventListener('click', toggle);
};
catalogFilterModalInit();

const checkoutProgressBarInit = () => {
  const checkoutProgressSteps = document.querySelector('.checkout-progress-steps');
  if (!checkoutProgressSteps) {
    return;
  }

  // set height of progress bar (same as container with progress steps)
  const progressContainer = document.querySelector('.checkout-progress');
  const progressBar = document.querySelector('.checkout-progress-bar');
  progressBar.style.height = `${progressContainer.offsetHeight - 64}px`;

  // set progress bar progress
  const progressBarFull = document.querySelector('.checkout-progress-bar__full');
  const currentProgressItem = document.querySelector('.checkout-progress-steps li.current');

  if (currentProgressItem) {
    progressBarFull.style.top = `${currentProgressItem.offsetTop - 16}px`;
  }
};
checkoutProgressBarInit();

const checkoutOptionsItemInit = () => {
  const checkoutOptionsItem = document.querySelector('.checkout-options-item');
  if (!checkoutOptionsItem) {
    return;
  }

  const activeClass = 'checkout-options-item--active';
  const paymentItems = document.querySelectorAll('.checkout-options-item');
  const paymentRadios = document.querySelectorAll('.checkout-options-item .checkout-option-radio input[type=radio]');

  paymentRadios.forEach((radio) => {
    radio.addEventListener('change', (event) => {
      paymentItems.forEach((item) => item.classList.remove(activeClass));

      if (event.target.checked) {
        event.target.closest('.checkout-options-item')
          .classList
          .add(activeClass);
      }
    });
  });
};
checkoutOptionsItemInit();

const checkoutConfirmSummaryInit = () => {
  const checkoutConfirmSummaryTitle = document.querySelector('.checkout-confirm-summary__title');
  if (!checkoutConfirmSummaryTitle) {
    return;
  }

  const checkoutConfirmSummaryContent = document.querySelector('.checkout-confirm-summary__accordion');

  checkoutConfirmSummaryTitle.addEventListener('click', () => {
    checkoutConfirmSummaryTitle.classList.toggle('active');
    checkoutConfirmSummaryContent.classList.toggle('show');
  });
};
checkoutConfirmSummaryInit();

const partnersDataTablesInit = () => {
  const tables = document.querySelectorAll('.js-partners-datatables');
  tables.forEach((table) => {
    new DataTable(table, {
      searchable: true,
      sortable: true,
      perPageSelect: false,
      paging: false,
      labels: {
        placeholder: 'Поиск',
        perPage: '{select} строк на странице',
        noRows: 'Пусто',
        info: 'Отображается с {start} по {end} из {rows} строк',
      }
    });
  });
};
partnersDataTablesInit();

const productImagesSliderInit = () => {
  let productImagesSlider = undefined;

  function enableProductsSlider() {
    productImagesSlider = new Swiper('.product-info__swiper-container', {
      pagination: {
        el: '.product-info__swiper-pagination',
        clickable: true,
        type: 'bullets',
      },
    });
  }

  function disableProductsSlider() {
    if (productImagesSlider !== undefined) {
      productImagesSlider.destroy(true, true);
    }
  }

  const breakpoint = window.matchMedia('(max-width:1023px)');
  const breakpointChecker = function breakpointChecker(event) {
    event.matches ? enableProductsSlider() : disableProductsSlider();
  };

  breakpoint.addEventListener('change', breakpointChecker);
  breakpointChecker(breakpoint);
};
productImagesSliderInit();
